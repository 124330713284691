// Map number x from range [a, b] to [c, d]

const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position
const getMousePos = e => {
    return {
        x : e.clientX,
        y : e.clientY
    };
};



export {
    map,
    clamp,
    lerp,
    getMousePos,
};
