<template>
    <div :style="{backgroundColor:color}" class="other-brands-mobile">
        <fancy-text :reverse="true" id="fancy-image-gallery" title="Other Brands" :color="titleColor"/>
        <div class="image-list" id="other-brads-mobile-slider">
            <router-link v-for="(item,index) in slides"
                         :key="index"
                         :to="item.slug"
                         :id="`item${index}`"
                         class="list-item slide-item">
                <span class="title">{{ item.title }}</span>
                <div class="hover-reveal">
                    <div class="hover-reveal__inner">
                        <div class="hover-reveal__img"
                             :style="{backgroundImage: `url(${item.path})`}"></div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import FancyText from "@/components/custom/FancyText";
import drag from "@/scripts/draggableSlider";

export default {
    props: {
        slides: {
            type: Array,
            default: () => []
        },
        config: {
            type: [Object, Array],
            default: () => {
            }
        },
        color: {
            type: String,
            default: () => []
        },
    },
    components: {
        FancyText
    },
    computed: {
        titleColor() {
            return this.config?.colorText || '#740B43'
        }
    },
    methods: {
        slideAnimation() {
            let itemImages = document.querySelectorAll(`#other-brads-mobile-slider.slide-item .item-image`)

            itemImages.forEach((item, index) => {
                let fromLeftDistance = item.getBoundingClientRect()?.left / innerWidth;
                let delta = Math.sin((index + 1) / 10) * 2.5

                let posY = fromLeftDistance * 150 * delta;
                this.gsap.set(item, {
                    y: posY / 5,
                    force3D: false,
                })
            })
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                drag(this.slideAnimation, true, false, 'other-brads-mobile-slider')
            }, 300)
        })
    }
}
</script>

<style lang="scss" scoped>
.other-brands-mobile /deep/ {
    width: 100%;
    background: #740B43;
    position: relative;

    .fancy-text {
        position: absolute;
        top: 0;

        .title {
            width: 100%;
        }
    }

    .image-list {
        @include center-children;
        width: fit-content;
        padding: 67px 47px 55px;
        @media only screen and (max-width: 767px) {
            padding: 42px 47px 20px;
        }

        .list-item {
            cursor: pointer;
            position: relative;
            text-decoration: none;
            padding: 10px 0;
            margin-right: 80px;
            @media only screen and (max-width: 767px) {
                margin-right: 75px;
            }

            &:last-child {
                margin-right: 0;
            }

            .title {
                display: block;
                position: absolute;
                color: white;
                font-size: 16px;
                top: 50%;
                left: 50%;
                width: 120%;
                transform: translate(-50%, -50%);
                z-index: 12;

                &:hover {
                    color: #E5B676;
                }
            }

        }
    }

    .hover-reveal {
        position: relative;
        width: 98px;
        height: 145px;
        top: 0;
        left: 0;
        pointer-events: none;
        will-change: transform;
        opacity: 1;
        z-index: 1;
        transform: rotateZ(15deg);

    }

    .hover-reveal__inner {
        width: 100%;
        height: 100%;
        position: relative;
        will-change: transform;
        overflow: unset;
    }

    .hover-reveal__img {
        background-size: cover;
        background-position: 50% 50%;
        transform-origin: 50% 100%;
        width: 100%;
        height: 100%;
        position: relative;
        will-change: transform;
        opacity: .7;
    }
}
</style>
