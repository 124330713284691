<template>
    <div :style="{ backgroundColor: color }" class="other-brands-desktop">
        <fancy-text
            :reverse="true"
            id="fancy-image-gallery"
            :title="config.header.title"
            :color="titleColor"
        />
        <div class="image-list">
            <router-link
                v-for="(item, index) in slides"
                :key="index"
                :to="`/${activeLanguage.id}/${item.link}`"
                :id="`item${index}`"
                class="list-item"
            >
                <span class="title">{{ item.title }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import MenuItem from "@/scripts/ImageGallery";
import FancyText from "@/components/custom/FancyText";
import { SUPPORTED_LANGUAGES } from "@/constants/trans";
import { mapGetters } from "vuex";
export default {
    props: {
        slides: {
            type: Array,
            default: () => []
        },
        color: {
            type: String,
            default: () => []
        },
        config: {
            type: [Object, Array],
            default: () => {}
        }
    },
    components: {
        FancyText
    },
    computed: {
        ...mapGetters({
            lang: "language/getLang"
        }),
        titleColor() {
            return this.config?.colorText || "#740B43";
        },
        activeLanguage() {
            return (
                SUPPORTED_LANGUAGES?.find(item => item.id === this.lang) || {}
            );
        }
    },
    methods: {
        render() {
            this.animatableProperties = {
                // translationX
                tx: { previous: 0, current: 0, amt: 0.08 },
                // translationY
                ty: { previous: 0, current: 0, amt: 0.08 },
                // Rotation angle
                rotation: { previous: 0, current: 0, amt: 0.05 }
            };
            let as = document.querySelectorAll(".image-list .list-item");
            // Array for the MenuItem instances
            this.menuItems = [];
            as.forEach((menuItemEl, index) =>
                this.menuItems.push(
                    new MenuItem(
                        menuItemEl,
                        this.animatableProperties,
                        this.slides?.[index]
                    )
                )
            );
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.render();
        });
    }
};
</script>

<style lang="scss" scoped>
.other-brands-desktop /deep/ {
    @include center-children;
    width: 100%;
    height: 665px;
    background: #740b43;
    position: relative;
    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
        height: 500px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        height: 380px;
    }

    .fancy-text {
        position: absolute;
        top: 0;

        .title {
            width: 100%;
        }
    }

    .image-list {
        @include center-children;

        .list-item {
            cursor: pointer;
            position: relative;
            text-decoration: none;
            padding: 20px 0;
            margin-right: 40px;
            z-index: 8;

            &:last-child {
                margin-right: 0 !important;
            }

            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                margin-right: 80px;
            }

            .title {
                display: block;
                position: relative;
                z-index: 1;
                color: white;
                font-size: 34px;
                opacity: 0.7;
                @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                    font-size: 21px;
                }

                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    font-size: 16px;
                }

                &:hover {
                    color: #e5b676;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.hover-reveal {
    position: absolute;
    z-index: -1;
    width: 303px;
    height: 454px;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    will-change: transform;
    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
        width: 223px;
        height: 335px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        width: 170px;
        height: 255px;
    }
}

.hover-reveal__inner {
    width: 100%;
    height: 100%;
    position: relative;
    will-change: transform;
    overflow: hidden;
}

.hover-reveal__img {
    background-size: cover;
    background-position: 50% 50%;
    transform-origin: 50% 100%;
    width: 100%;
    height: 100%;
    position: relative;
    will-change: transform;
}
</style>
