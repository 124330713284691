import gsap from "gsap";
import {CustomEase} from 'gsap/dist/CustomEase';
import store from '@/store/index'

gsap.registerPlugin(CustomEase);

const clamp = (val, min, max) => Math.max(Math.min(val, min), max);
const lerp = (a, b, n) => (1 - n) * a + n * b;

const drag = (slideAnimation, scale = false, contentParallax = false, className = 'draggable-slider', getPadding) => {
    (function () {
        let lastTime = 0;
        let vendors = ['ms', 'moz', 'webkit', 'o'];
        for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
            window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
            window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame']
                || window[vendors[x] + 'CancelRequestAnimationFrame'];
        }
        if (!window.requestAnimationFrame)
            window.requestAnimationFrame = function (callback,) {
                let currTime = new Date().getTime();
                let timeToCall = Math.max(0, 16 - (currTime - lastTime));
                let id = window.setTimeout(function () {
                        callback(currTime + timeToCall);
                    },
                    timeToCall);
                lastTime = currTime + timeToCall;
                return id;
            };
        if (!window.cancelAnimationFrame)
            window.cancelAnimationFrame = function (id) {
                clearTimeout(id);
            };
    }());
    changeElementVisibility()
    if (typeof slideAnimation === 'function') slideAnimation()

    let $box = document.querySelector(`#${className}`),
        startX,
        clientX,
        currentX,
        isDown = false,
        animation,
        oldX,
        padding = getPadding ? getPadding(window.innerWidth) : 0,
        sceneWidth = -$box.getBoundingClientRect()?.width + (window.innerWidth - 2 * padding),
        posX,
        prevX;

    function changeElementVisibility() {
        let elements = document.querySelectorAll(`#${className} .slide-item`);

        const onEntry = (entry) => {
            entry.forEach((change) => {
                if (change.target?.getBoundingClientRect()?.left >= 0) {
                    if (change.isIntersecting) {
                        gsap.to(change.target.children[0], {
                            clipPath: 'inset(0% 0% 0% 0%)',
                            duration: .6,
                            ease: CustomEase.create("custom", "M0,0,C0.092,0.608,0.32,0.862,1,1")
                        })
                        change.target.classList.add('visible')
                        if (!contentParallax) return
                        gsap.to(change.target.children[1], {
                            y: '-=50px',
                            duration: .6,
                            delay: .1,
                            force3D: false,
                            ease: CustomEase.create("custom", "M0,0,C0.092,0.608,0.32,0.862,1,1")
                        })
                    } else {
                        gsap.to(change.target.children[0], {
                            clipPath: 'inset(100% 0% 0% 0%)',
                            duration: .6,
                            ease: CustomEase.create("custom", "M0,0,C0.092,0.608,0.32,0.862,1,1")
                        })
                        change.target.classList.remove('visible')

                        if (!contentParallax) return
                        gsap.to(change.target.children[1], {
                            y: 0,
                            force3D: false,
                            duration: .6,
                            ease: CustomEase.create("custom", "M0,0,C0.092,0.608,0.32,0.862,1,1")
                        })
                    }
                }
            });
        };

        let observer = new IntersectionObserver(onEntry, {});

        for (let elm of elements) {
            observer.observe(elm);
        }

    }

    function animate() {
        animation = requestAnimationFrame(animate);
        let $boxPosition = $box.getBoundingClientRect();
        oldX = $boxPosition.left - padding;

        posX = clamp(
            currentX + (clientX - startX) * 3.5,
            0,
            sceneWidth,
        );

        if (typeof slideAnimation === 'function') slideAnimation()
        posX = lerp(oldX, posX, 0.03)
        if (posX - prevX < 0.00005 && posX - prevX > -0.00005) {
            store.dispatch('drag/setDragging', false)
        } else {
            store.dispatch('drag/setDragging', true)
        }
        prevX = posX
        $box.style.transform = `translate(${posX}px,0px)`;
    }

    function mouseMoveAnimation() {
        gsap.to(`#${className} .slide-item`, {
            ease: CustomEase.create("custom", "M0,0,C0.078,0.714,0.22,0.93,1,1"),
            duration: .8,
            force3D: false
        })
    }

    function mouseDownAnimation() {
        gsap.set(`.draggable`, {
            cursor: 'grabbing',
        })
        if (!scale) return

        gsap.to(`#${className} .slide-item`, {
            scale: .95
        })
    }

    function mouseUpAnimation() {
        gsap.set(`.draggable`, {
            cursor: 'grab',
        })
        if (!scale) return

        gsap.to(`#${className} .slide-item`, {
            scale: 1
        })
    }

    const eventStart = (event) => {
        if (sceneWidth > 0) return
        if (animation) cancelAnimationFrame(animation);
        let $boxPosition = $box.getBoundingClientRect();
        isDown = true;
        oldX = $boxPosition.left - padding;
        posX = $boxPosition.left - padding;
        startX = event.clientX || event.touches?.[0]?.clientX || event.changedTouches?.[0]?.clientX;
        clientX = event.clientX || event.touches?.[0]?.clientX || event.changedTouches?.[0]?.clientX;
        currentX = $boxPosition.left - padding;

        mouseDownAnimation();
        animate();
    }
    const eventMove = (event) => {
        // if (window.innerWidth < 1024) {
        //     event.stopPropagation()
        //     event.preventDefault()
        // }
        if (!isDown) return
        mouseMoveAnimation()
        clientX = event.clientX || event.touches?.[0]?.clientX || event.changedTouches?.[0]?.clientX;
    }
    const eventUp = (event) => {
        clientX = event.clientX || event.touches?.[0]?.clientX || event.changedTouches?.[0]?.clientX;
        isDown = false;
        mouseUpAnimation()
    }
    const eventLeave = () => {
        isDown = false;
        mouseUpAnimation()
    }
    const resize = () => {
        padding = getPadding ? getPadding(window.innerWidth) : 0
        sceneWidth = -$box.getBoundingClientRect()?.width + (window.innerWidth - 2 * padding)
        posX = 0;
        oldX = 0;
        startX = 0
        prevX = 0
        clientX = 0
        currentX = 0
    }
    const resizeHandler = () => {
        window.addEventListener('resize', resize)
    }


    $box.addEventListener("mousedown", eventStart, false);

    $box.addEventListener('touchstart', eventStart, false)

    $box.addEventListener("mousemove", eventMove, false);
    $box.addEventListener("touchmove", eventMove, false);

    $box.addEventListener("mouseup", eventUp, false);
    $box.addEventListener("touchend", eventUp, false);

    $box.addEventListener("mouseleave", eventLeave, false);
    $box.addEventListener("touchcancel", eventLeave, false);
    resizeHandler()
}

export default drag
