<template>
    <div data-scroll :data-scroll-id="name" class="other-brands">
        <mouse-changer v-if="!mobileDevice" :type="mouseTypes.Custom">
            <other-brands-desktop
                :color="color"
                :config="config"
                :slides="brandSlides"
            />
        </mouse-changer>
        <other-brands-mobile
            :color="color"
            :config="config"
            :slides="brandSlides"
            v-else
        />
    </div>
</template>

<script>
import OtherBrandsDesktop from "@/components/PageComponents/OtherBrandsDesktop";
import OtherBrandsMobile from "@/components/PageComponents/OtherBrandsMobile";
import { mapGetters } from "vuex";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: [Object, Array],
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        MouseChanger,
        OtherBrandsDesktop,
        OtherBrandsMobile
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        mobileDevice() {
            return this.device?.size < 1024;
        },
        brandSlides() {
            return this.data?.list?.map(item => ({
                path: item?.image?.[0]?.devices[this.device?.type],
                title: item?.title,
                slug: item.slug,
                link: item.link
            }));
        },
        color() {
            return this.config.colorBg;
        }
    }
};
</script>

<style lang="scss" scoped>
.other-brands {
    position: relative;
    display: block;
    padding-top: 205px;

    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding-top: 182px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        padding-top: 127px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding-top: 127px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 78px;
    }
}
</style>
